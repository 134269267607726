import React from 'react'

let Scholarships=()=>(
	<article className="transparentContainer flex450">
	<h2>Scholarships</h2>
	<div className='whiteCard'>
	<h2>If you fall into one of these categories your <span className='highlighter'>tuition would be reduced by 500€</span></h2>

	<p><strong>Students over 40:</strong> because we know it’s harder to commit to learning at a certain age and we are willing to help.</p>

	<p><strong>Women in tech:</strong> we're proud to say that 45% of our graduates are women, and we're committed to achieving full gender equality. This is especially significant given that less than 9% of women work in tech, according to <a href='https://survey.stackoverflow.co/2022/#section-demographics-gender' target='_blank'>StackOverflow's 2022 industry survey</a>.</p>

	<p><strong className='highlighter'>How to get a scholarship:</strong></p>

	<p>After signing up online you will get a student's form to fill out where you can select the applicable scholarship. Your final tuition quote would be calculated based on that and you will get links for making the remaining payments.</p>

	</div>
	</article>
	)
export default Scholarships