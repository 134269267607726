// import React, { useState, useEffect } from "react";
// import "./ProgressBar.css";

// const ProgressBar = ({ percentage }) => {
//     const [progress, setProgress] = useState(0);

//     const getBarColor = (progress) => {
//         if (progress < 40) return "#0fff50"; // Green
//         if (progress >= 40 && progress <= 80) return "yellow"; // Yellow
//         return "#FF00C7"; // Purple
//     };

//     useEffect(() => {
//         if (progress < percentage) {
//             const interval = setInterval(() => {
//                 setProgress((prev) => {
//                     if (prev >= percentage) {
//                         clearInterval(interval); // Stop interval when target is reached
//                         return prev;
//                     }
//                     return prev + 1;
//                 });
//             }, 20); // Adjust interval timing for smooth animation
//             return () => clearInterval(interval); // Cleanup interval on unmount
//         }
//     }, [progress, percentage]);

//     return (
//         <div className="availabilityContainer">
//             <p>Seats&nbsp;left: </p>
//             <span className="progress-wrap">
//                 <span
//                     className="progress-bar"
//                     style={{
//                         width: `${progress}%`,
//                         backgroundColor: getBarColor(progress),
//                         transition: "width 0.5s, background-color 0.5s",
//                     }}
//                 ></span>
//             </span>
//         </div>
//     );
// };

// export default ProgressBar;

import React, { useState, useEffect, useRef } from "react";
import "./ProgressBar.css";

const ProgressBar = ({ percentage }) => {
    const [progress, setProgress] = useState(0);
    const intervalRef = useRef(null);

    const getBarColor = (progress) => {
        if (progress < 25) return "#0fff50"; // Green
        if (progress >= 25 && progress <= 60) return "yellow"; // Yellow
        return "#FF00C7"; // Purple
    };

    useEffect(() => {
        if(percentage < 100){
        const moveProgressBar = () => {
            setProgress((prev) => (prev >= percentage ? 0 : prev + 1));
        };

        intervalRef.current = setInterval(moveProgressBar, 100); // Adjust interval as needed

        return () => {
            clearInterval(intervalRef.current);
        };
    }else{
        setProgress(100);
    }
    }, [percentage]);

    return (
        <div className='availabilityContainer'>
            <p>Enrolled: </p>
            <span className="progress-wrap">
                <span
                    className="progress-bar"
                    style={{
                        width: `${progress}%`,
                        backgroundColor: getBarColor(progress),
                        transition: "width 0.5s, background-color 0.5s",
                    }}
                ></span>
            </span>
        </div>
    );
};

export default ProgressBar;
